.neo-card {
  /* border-radius: 40px; */
  background: #fff;
  /* box-shadow: 5px 5px 12px #191b1f, -5px -5px 12px #3b3f49; */
}

.input-field {
  /* background-color: rgba(38, 38, 38, 0.4); */
  /* box-shadow: inset 0px 0px 15px #252525; */
}

.tabs button {
  /* background-color: lightgray; */
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  border: none;
  color: white;
  cursor: pointer;
  padding: 12px;
  width: 100%;
  transition: all 0.25s ease-out;
  border-radius: 40px;
  margin: 10px;
}

.tabs button:hover {
  background: #2a2d34;
  box-shadow:
    5px 5px 12px #191b1f,
    -5px -5px 12px #3b3f49;
  color: #ffbff6;
}

.tabs button:disabled {
  background: #c6007e;
  box-shadow:
    5px 5px 12px #191b1f,
    -5px -5px 12px #3b3f49;
  color: #ffbff6;
}

.availability-table {
  background: #2a2d34;
  color: #ffbff6;
}
.availability-table th {
  /* background: #2a2d34; */
  /* box-shadow: 2px 2px 6px #191b1f, -2px -2px 6px #3b3f49; */

  min-width: 40px;
  padding: 4px;
}
.availability-table td {
  /* background: white; */
  border: 1px solid;
  color: #2a2d34;
  min-width: 40px;
  padding: 8px;
  text-align: center;
}
.availability-table td:hover {
  background-color: #c6007e;
  cursor: pointer;
}

/* Calendar */

.react-calendar {
  background-color: transparent !important;
  border: 1px solid gray !important;
}

/* Navbar */
nav {
  background-color: #628b00;
}
nav a {
  padding: 0.5rem 1rem;
  color: white;
}
nav a .title {
  display: none;
}
nav a.active {
  background-color: #c6007e;
  color: white;
  /* font-weight: bold; */
}
nav a.active .title {
  display: block;
}

.songlist {
  min-width: 275px;
  height: calc(100vh - 124px);
  position: sticky;
  top: 64px;
}

.setlist {
  width: 250px;
  height: 380px;
}

.availability-table {
  overflow-x: hidden;
}

@media only screen and (min-width: 960px) {
  nav a .title {
    display: block;
  }
  /* .for-mobile {
    display: none;
  }
  .for-desktop {
    display: block;
  } */
}
@media only screen and (max-width: 960px) {
  .container {
    width: 100%;
  }
  /* .for-mobile {
    display: block;
  }
  .for-desktop {
    display: none;
  } */
  /* .songlist {
    display: none;
  } */
  .content {
    padding: 50px;
  }
}
