/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
} */

.calendar-container {
  max-width: 360px;
  /* width: 100%; */
  /* text-align: center;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px; */
}

/* .calendar {
  max-width: 300px;
  margin: 0 auto;
} */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-cell {
  /* border: 1px solid #ccc; */
  padding: 0.75rem;
  text-align: center;
  font-size: 0.75rem;
}
